const authors: ReadonlySet<string> = new Set([
  "aching",
  "Aidan",
  "AlkarimNasser",
  "alayna-r-kolodziechuk",
  "all",
  "Assaf Weisz",
  "aunterman",
  "braedon-beaulieau",
  "ChrisKay",
  "ChrisSnoyer",
  "cmcivor",
  "eashan-karnik",
  "EricJanssen",
  "erin-adele-krawchuk",
  "frahman",
  "Ian",
  "Ilana-Ben-Ari",
  "info@clausehound.com",
  "irbaz-wahab",
  "Jenny",
  "kboutin",
  "LisaWinning",
  "margaret",
  "Mauritius",
  "mnazeer",
  "Mondaq",
  "Natalka",
  "NathanLev",
  "NavidNathoo",
  "phil-weiss",
  "Rajah",
  "richard@clausehound.com",
  "Ryan Grant Little",
  "sahilkanaya",
  "Sheetal Jaitly",
  "Thomas Southmayd",
] as const);

export { authors };
